<template>
    <div class="page-box">
<!--        <headBar ref="headerHeight" title="超鹿自助健身卡" left-arrow @click-left="newAppBack"/>-->
        <nav-bar header-title="购买月卡限时加赠5天" backColor="#fff" :show-back="true" :header-back="newAppBack" ref="head"/>
        <app-page id="box" class="page-img">
            <div class="main-pic1">
                <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/activity/new/1.png" alt="">
                <div @click="shareFun" class="share-btn row-center-center">分享</div>
            </div>
            <div class="btn-img">
                <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/activity/new/2.png" alt="">
                <p>
                    <img v-if="activityStatus === 1" src="https://img.chaolu.com.cn/ACT/sell-membership-2022/activity/2-2.png" alt="">
                    <img @click="showEquityCity" v-else-if="activityStatus === 2" src="https://img.chaolu.com.cn/ACT/sell-membership-2022/activity/2-1.png" alt="">
                    <img v-else-if="activityStatus === 3" src="https://img.chaolu.com.cn/ACT/sell-membership-2022/activity/2-3.png" alt="">
                </p>
            </div>
            <img class="main-pic2" src="https://img.chaolu.com.cn/ACT/sell-membership-2022/activity/new/3.png" alt="">
            <img class="main-pic" src="https://img.chaolu.com.cn/ACT/sell-membership-2022/activity/new/4.png" alt="">
        </app-page>
        <!--支付成功弹窗-->
        <van-popup v-model="showPaySuccess" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false">
            <div class="pay-box">
                <div class="pay-box-title">
                    <img
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/group12.png"
                        alt="">
                    <div>购买成功</div>
                </div>
                <div @click="jumpMyBagCopy()" class="pay-share-btn">去我的卡包查看</div>
                <div class="pay-box-tips">会员卡已到账至 “个人中心-自助卡”</div>
            </div>
            <img @click="showPaySuccess = false" class="result-close"
                 src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png"
                 alt="">
        </van-popup>
        <!--错误弹窗提示-->
        <van-popup v-model="showError" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false">
           <div class="error-box">
               <h4>提示</h4>
               <p>{{errorMsg.msg}}</p>
               <span @click="$router.push('/month-card/sell-record?active=1&userMembershipCardId='+selectItem.equityCardId)" v-if="errorMsg.showAutoRenew === 1">自动续费管理></span>
               <div @click="showError = false" class="row-center-center">我知道了</div>
           </div>
        </van-popup>
        <!--城市确认-->
        <van-popup v-model="showCheckCity" :overlay="true" style="background-color: transparent;width: 84vw;" closeable>
            <div class="error-box">
                <h4>提示</h4>
                <p>您当前购买的自助健身卡，仅限<span style="font-weight: bold;color: #ED5C42">{{ citys[equityCityIndex] }}</span>所有门店使用，请确认后再付款！</p>
                <div @click="checkBuy" class="row-center-center">我知道了</div>
            </div>
        </van-popup>
        <!-- 协议弹窗 -->
        <van-popup v-model="showProtocol" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false">
            <div class="protocol-box">
                <h4>“超鹿运动”自助月卡服务协议</h4>
                <p>{{ protocolRule }}</p>
                <div v-if="doneProtocol" @click="showProtocol = false" class="row-center-center">我知道了</div>
                <div v-else class="row-center-center gray">请认真阅读以上内容({{ timeProtocol }})</div>
            </div>
        </van-popup>
        <!-- 权益卡 选择城市 -->
        <van-popup title="选择购买城市" v-model="equityCityShow" position="bottom" round closeable safe-area-inset-bottom>
            <div class="city-select fw6">
                <h3>选择购买城市</h3>
                <div @click="chooseEquityCity(index)" :class="['select-item', 'row-between-center', equityCityIndex === index ? 'active' : '']" v-for="(item,index) in citys" :key="item">
                    <span>{{ item }}</span>
                    <img v-show="equityCityIndex === index" src="https://img.chaolu.com.cn/ACT/double11-2022/index/choose-icon.png" alt="">
                </div>
                <div class="button row-center-center" @click="confirmCityChoose">下一步</div>
            </div>
        </van-popup>
        <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl"></payPopupCard>
        <!--  分享配置  -->
        <common-share :value="shareShow" :shareParams="shareParams" @close="shareSuccess" @share-success="shareSuccess" :shareItem="shareItem" :miniSharePoster="appTypeStr === 'mini'">
            <template slot="createDom">
                <!-- 需要绘制的dom 写这 -->
                <div class="canvas-dom">
                    <img class="bg" src="https://img.chaolu.com.cn/ACT/sell-membership-2022/activity/new/6.png" alt=""/>
                    <div class="content row-between-center">
                        <div class="row-start-center hbb">
                            <img class="head flex-none" :src="userInfo.headImg" alt=""/>
                            <div class="name">
                                <p class="p1 f28 fw6 ellipsis">{{ userInfo.nickName }}</p>
                                <p class="p2 f22">邀您一起抢购月卡</p>
                            </div>
                        </div>
                        <div v-if="userInfo.qrCode" class="qr col-center-center flex-none">
                            <img :src="`data:image/png;base64,${userInfo.qrCode}`" alt=""/>
                        </div>
                    </div>
                </div>
            </template>
        </common-share>
    </div>
</template>
<script>
import userMixin from '@/mixin/userMixin';
import navBar from '@/components/nav-bar/nav-bar'
import appPage from "@/common/components/appPage";
import payPopupCard from "@/components/pay-popup-card";
import commonShare from '@/components/commonShare'
import {protocol} from "@/views/month-card/const";
import {
    appPay,
    appPaySuccess,
    initBack,
    newAppBack, webAppShare
} from "@/lib/appMethod";
import wx from "weixin-js-sdk";

export default {
    data() {
        return {
            shareItem: ['minifriend', 'postermessage', 'save'],
            shareParams: {
                title: '放假来不了？抢购月卡加赠5天！',
                miniImage: 'https://img.chaolu.com.cn/ACT/sell-membership-2022/activity/new/5.png',
                path: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/sell-membership/activity&userId=1&token=1',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
                url: 'url',
                multiple: "0.95",
                userName: "gh_7f5b57b6a76e",
            },
            shareShow: false,
            equityCityIndex: '',
            citys: ['福州', '厦门', '广州'],
            equityCityShow: false,
            cardList: [
                {
                    equityCardItemId: 222,
                    cityId: '3',
                    equityCardId: 1000023
                },
                {
                    equityCardItemId: 223,
                    cityId: '036652995ac5451fbb6791c3491e1c2d',
                    equityCardId: 1000024
                },
                {
                    equityCardItemId: 224,
                    cityId: '818447834079563776',
                    equityCardId: 1000025
                }
            ],
            showPaySuccess: false,
            showCheckCity: false, // 城市确认弹窗
            isShowCheckCityName: '', // 弹出城市确认弹窗的城市
            showError: false,
            errorMsg: {},
            showPayPopup: false,
            payData: {},
            payUrl: '',
            ticketSource: 'WEB', // 购买来源 WEB 首页主动进入  SMS 短信进入
            activityStatus: 0,
            userInfo: {
                // 用户信息
                qrCode: '',
                nickName: '',
                headImg: '',
            },
            showProtocol: false, // 勾选用户协议
            doneProtocol: false,//已阅读完协议
            timeProtocol:  window.location.origin.indexOf('web.chaolu.com') > -1 ? 6 : 1, // 阅读倒计时
            protocolRule: protocol,
        };
    },
    mixins: [userMixin],
    components: {
        appPage,
        navBar,
        payPopupCard,
        commonShare
    },
    mounted() {
        document.addEventListener("visibilitychange", () => {
           if (document.visibilityState === "hidden") {
                this.showPaySuccess = false
           }
           if(document.visibilityState === "visible"){
               this.getActivityInfo()
           }
        })
    },
    async created() {
        initBack();
        await this.$getUserId();
        // this.currentCityId = await this.$getCityId() || '3'
        // this.currentCityId = this.$route.query.cardCityId || this.currentCityId
        this.getActivityInfo()
        this.initQr()
        if(this.appTypeStr === 'mini'){
            wx.miniProgram.postMessage({
                data: {
                    type: 'share',
                    shareImage: this.shareParams.miniImage,
                    shareTitle: this.shareParams.title,
                    shareUrl: this.shareParams.path,
                }
            })
            appPaySuccess().then(res=>{
                this.showPaySuccess = true
            })
        }
    },
    methods: {
        newAppBack,
        // 获取主会场时间
        getActivityInfo() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                overlay: true,
                loadingType: 'spinner',
            })
            this.$axios.post(this.baseURLApp + '/activity/getActivityTime', {
                userId: this.userId,
                token: this.token,
                hasWx: true,
                activityNo: '202212EndSale'
            }).then((res) => {
                let diffEndTime = res.data.diffEndTime, diffStartTime = res.data.diffStartTime
                if(diffStartTime > 0){
                    this.activityStatus = 1 // 活动未开始
                    this.time = diffStartTime
                }else if(diffEndTime > 0){
                    this.activityStatus = 2 // 活动已开始
                    this.time = diffEndTime
                }else{
                    this.activityStatus = 3 // 活动已结束
                }
                this.$toast.clear()
            })
        },
        showEquityCity(v) {
            this.equityKey = v
            this.equityCityShow = true
        },
        chooseEquityCity(i) {
            this.equityCityIndex = i
        },
        confirmCityChoose() {
            if (this.equityCityIndex === '') {
                return this.$toast('请选择购买城市')
            }
            this.equityCityShow = false
            this.checkAwaitOrder()
        },
        // 校验是否有使用优惠券的待支付订单
        checkAwaitOrder() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.$axios.post(`${this.baseURLApp}/order/equityCardUser/monthlyCardToBePaidCouponVerification`, {
                userId: this.userId,
                userToken: this.token,
            }, false).then(res => {
                if(res.data) {
                    this.checkCity()
                    this.$toast.clear()
                }
            }).catch((r) => {
                this.errorMsg = {msg: r.msg, showAutoRenew: r.data?r.data.showAutoRenew:0}
                this.showError = true
                this.$toast.clear()
            })
        },
        // 校验是否阅读完月卡协议
        checkProtocol(show) {
            if(!this.doneProtocol){
                this.showProtocol = true
                let timer = setInterval(() => {
                    this.timeProtocol--
                    if(this.timeProtocol < 1){
                        clearInterval(timer)
                        this.doneProtocol = true
                        this.protocol = true
                    }
                }, 1000)
                return  false
            }else{
                if(show){
                    this.showProtocol = true
                }
                return true
            }
        },
        // 提示购买城市
        checkCity() {
            if(this.isShowCheckCityName && this.isShowCheckCityName === this.citys[this.equityCityIndex]){
                this.checkBuy()
                return false
            }
            this.showCheckCity = true
            this.isShowCheckCityName = this.citys[this.equityCityIndex]
        },
        // 校验是否可以买月卡
        checkBuy() {
            this.showCheckCity = false
            if(!this.checkProtocol()){
                return  false
            }

            let param = {
                equityCardItemId: this.cardList[this.equityCityIndex].equityCardItemId,
                equityCardId: this.cardList[this.equityCityIndex].equityCardId,
                userId: this.userId,
                token: this.token,
                source: 'SELF_BUY'
            }
            this.$axios.post(`${this.baseURLApp}/equityCard/check/buy`,param, false).then(res => {
                if(res.data) {
                   this.buyCard()
                }
            }).catch((r) => {
                this.errorMsg = {msg: r.msg, showAutoRenew: r.data?r.data.showAutoRenew:0}
                this.showError = true
            })
        },
        // 购买
        buyCard(){
            let url = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership/activity&userId=1&cityId=1&venueId=${this.$route.query.venueId || '31'}`
            let info = this.cardList[this.equityCityIndex]
            let payData = {
                subject: '自助健身月卡',
                extendsJson: {
                    appVersion: '2.50.11',
                    seriesName: '自助健身月卡',
                    ticketSource: this.ticketSource,
                    discountId: this.selectedCouponId,
                },
                orderEquityCardCreateReq: {
                    source: 'SELF_BUY'
                },
                totalAmount: 178,
                venueId: this.venueId || '31',
                goodsNum: "1",
                isApp: '1',
                type: '14',
                selectedDiscount: 0,
                discountId: this.selectedCouponId,
                userId: this.userId,
                token: this.token,
                cityId: info.cityId,
                extendsType: '4',
                mouldId: info.equityCardItemId,
                whetherPrivacy: 0,
                originalPriceStr: 278,
                title: '购买内容',
            }
            if(this.appTypeStr === 'mini'){
                payData.wxPayType = 'applet'
            }
            // web支付
            this.showPayPopup = true
            this.payData = payData
            this.payUrl = url
            if( this.appTypeStr !== 'mini' ){
                appPaySuccess().then(res=>{
                    this.showPaySuccess = true
                    this.getCardList();
                    this.getVoucherList()
                })
            }
        },
        jumpMyBagCopy() {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/month-card/my-card&userId=1&token=1`
                })
            } else {
                this.$router.push({
                    path: '/month-card/my-card',
                })
            }
        },
        shareFun() {
            this.shareShow = true
            this.showPaySuccess = false
        },
        shareSuccess() {
            this.shareShow = false
        },
        initQr() {
            this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
                hasWx: true,
                userId: this.userId,
                token: this.token,
                width: 240,
                height: 240,
                webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
                scene: JSON.stringify({
                    cityId: '1',
                    webUrl: `${window.location.origin}/#/superdeer-activity/sell-membership/activity`,
                }),
            }).then((res) => {
                this.userInfo = res.data
            })
        },
    },
};
</script>
<style lang="less" scoped>

.bottom-rule{
    padding: 48px 32px 62px;
    h4{
        line-height: 36px;
        font-size: 36px;
        color: #232831;
    }
    p{
        margin-top: 14px;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        color: #3C454E;
        white-space: pre-line;
    }
}
.protocol-box{
    width: 630px;
    height: 900px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 0 auto;
    h4{
        font-size: 36px;
        font-weight: 600;
        color: #242831;
        line-height: 36px;
        text-align: center;
        padding-top: 58px;
    }
    p{
        &::-webkit-scrollbar{
            display: none;
        }
        height: 570px;
        overflow-x: auto;
        text-align: left;
        margin: 32px  auto 56px;
        width: 510px;
        font-size: 28px;
        font-weight: 400;
        color: #3C454E;
        line-height: 44px;
        white-space: pre-line;
    }
    div{
        margin: 0  auto;
        font-weight: bold;
        width: 510px;
        height: 96px;
        background: #FFDE00;
        border-radius: 8px;
        font-size: 32px;
        color: #242831;
        line-height: 32px;
        &.gray{
            background: #9AA1A9;
        }
    }
}
.page-box {
    min-height: 100vh;
    background: linear-gradient(180deg, #ABFA4B 0%, #ECFBDB 51%, #ABFA4B 100%);
    & > div {
        width: 750px;
    }
    .main-pic1{
        position: relative;
        img{
            width: 100%;
            display: block;
        }
        .share-btn{
            width: 88px;
            height: 56px;
            background-image: linear-gradient(90deg, #FBE7D9 0%, #DFA178 100%);
            position: absolute;
            right: 0;
            top: 196px;
            border-radius: 28px 0 0 28px;
            color: #262967;
            font-size: 24px;
            font-weight: bold;
        }
    }
    .main-pic{
        display: block;
        width: 694px;
        margin: 40px auto 120px;
    }
    .main-pic2{
        display: block;
        width: 694px;
        margin: 40px auto 0;
    }
    .btn-img{
        width: 694px;
        margin: 20px auto 0;
        position: relative;
        img{
            display: block;
            width: 100%;
        }
        p{
            width: 646px;
            height: 130px;
            position: absolute;
            left: 24px;
            bottom: 30px;
            z-index: 99;
            img{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.error-box{
    width: 630px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 0 auto;
    padding-bottom: 48px;
    &>span{
        display: block;
        text-align: center;
        margin-bottom: 24px;
        font-size: 28px;
        font-weight: bold;
        color: #0a7aff;
    }
    h4{
        font-size: 36px;
        font-weight: 600;
        color: #242831;
        line-height: 36px;
        text-align: center;
        padding-top: 58px;
    }
    p{
        text-align: center;
        margin: 32px  auto 56px;
        width: 510px;
        font-size: 28px;
        font-weight: 400;
        color: #3C454E;
        line-height: 44px;
    }
    div{
        margin: 0  auto;
        font-weight: bold;
        width: 510px;
        height: 96px;
        background: #FFDE00;
        border-radius: 8px;
        font-size: 32px;
        color: #242831;
        line-height: 32px;
    }
}
// 支付成功弹窗
.pay-box {
    width: 590px;
    box-sizing: border-box;
    margin: 0 auto;
    background: white url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-success-bg.png") no-repeat center top;
    background-size: 590px 554px;
    border-radius: 16px;
    overflow: hidden;
    padding-bottom: 40px;

    .pay-box-title {
        img {
            width: 128px;
            height: 128px;
            display: block;
            margin: 0 auto;
            padding: 64px 0 48px;
        }

        div {
            font-weight: bold;
            color: #242831;
            font-size: 36px;
            line-height: 42px;
            text-align: center;
        }
    }

    .pay-box-text {
        font-size: 28px;
        color: #242831;
        line-height: 40px;
        margin-top: 24px;
        text-align: center;
    }

    .pay-box-tips {
        font-size: 22px;
        color: #6C727A;
        line-height: 26px;
        text-align: center;
        width: 460px;
        margin: 40px auto 0;
        .s1 {
            color: #FF6E00;
            font-size: 22px;
        }
    }

    .pay-share-btn {
        width: 510px;
        height: 96px;
        display: block;
        margin: 128px auto 0;
        background: #FFDE00;
        font-weight: bold;
        color: #242831;
        font-size: 32px;
        line-height: 96px;
        text-align: center;
        border-radius: 8px;
    }
}

.result-close {
    width: 60px;
    height: 60px;
    margin: 48px auto 0;
    display: block;
}
.city-select {
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    h3 {
        margin-bottom: 68px;
    }

    .select-item {
        width: 686px;
        height: 128px;
        background: #F5F5F5;
        border-radius: 8px 8px 8px 8px;
        margin: 24px 0 0;
        padding: 0 32px;
        border: 1px solid transparent;
        box-sizing: border-box;
        &.active {
            border: 1px solid #242831;
        }

        img {
            width: 48px;
        }
    }

    .button {
        width: 686px;
        height: 96px;
        background: #FFDE00;
        border-radius: 8px 8px 8px 8px;
        margin: 64px 0 0;
    }
}
.canvas-dom {
    width: 654px;
    margin: auto;
    position: relative;

    .bg {
        width: 100%;
        display: block;
    }

    .content {
        width: 654px;
        position: absolute;
        bottom: 34px;
        left: 0;
        padding: 0 76px 0 31px;
        box-sizing: border-box;
    }

    .hbb {
        margin: 30px 0 0;
    }

    .head {
        width: 110px;
        height: 110px;
        border-radius: 50%;
    }

    .name {
        color: #242831;
        margin: 0 0 0 20px;

        .p1 {
            max-width: 300px;
        }

        .p2 {
            margin: 16px 0 0;
        }
    }

    .qr {
        //background-image: url(https://img.chaolu.com.cn/ACT/double11-2022/index/ewm-box.png);
        //background-size: 100%;
        margin: 15px 0 0;
        width: 104px;
        height: 104px;

        img {
            width: 121px;
            border-radius: 12px;
        }
    }
}
</style>
